<script lang="ts" setup>
const emit = defineEmits<{ (e: 'close'): void }>()
</script>

<template>
    <div class="feedback-form-success">
        <SvgoSuccess />
        <h4 class="feedback-form-success__title">
            {{ $t('feedbackForm.succeed') }}
        </h4>
        <p class="feedback-form-success__descr">
            {{ $t('feedbackForm.msgSuccessDesc') }}
        </p>

        <button
            class="feedback-form-success__btn-close"
            @click="emit('close')"
        >
            {{ $t('feedbackForm.ok') }}
        </button>
    </div>
</template>

<style lang="scss" scoped></style>
